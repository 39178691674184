* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
  list-style: none !important;
  text-decoration: none !important;
}
body {
  overflow-x: hidden !important;
  max-width: 100vw;
  background: #e8eef1;
}

:root {
  --primary-color: #52bbab;
  --black: #000;
  --light-black: #3d3636;
}

/* onbording1 */
.onbording-logo {
  height: 250px;
  width: 250px;
}
.onbording2-logo {
  height: 120px;
  width: 120px;
}
.onbording1-heading {
  font-size: 96px;
  letter-spacing: 15px;
}
.onbording1-subtitle {
  font-size: 20px;
  letter-spacing: 4px;
}

/* login1 */
.login-heading {
  font-size: 40px;
  color: var(--primary-color);
  font-weight: 600;
}
.login-subText {
  font-size: 16px;
  font-weight: 400;
  color: #9e9b9b;
}
.two-side-line {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #9e9b9b;
}
.two-side-line::before,
.two-side-line::after {
  content: "";
  border-top: 1px solid #9e9b9b;
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}
.two-side-line::after {
  margin: 0 0 0 20px;
}

/* forgot */
.forgot-heading {
  font-size: 40px;
  color: var(--light-black);
  font-weight: 600;
}
.sidebar {
  min-height: calc(100vh - 48px) !important;
}



/* project listing */
.project-list-scroll{
  padding-right: 15px;
}
.project-list-scroll::-webkit-scrollbar{
  width: 8px;
}
.project-list-scroll::-webkit-scrollbar-track{
  background: #ccc;
  border-radius: 10px; /* Rounded corners */

}
.project-list-scroll::-webkit-scrollbar-thumb {
  background-color: #000; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners */
}
.successfull-overlayer{
  background: #d6ecea70;
  backdrop-filter: blur(8px);
}
.fit-to-screen{
  min-height: calc(100vh - 123px);
}
.fulled-screen{
  min-height: calc(100vh - 48px);
}



.main-menu {
  background: #52bbab;
  height: 100%;
  left: 0;
  width: 60px; /* Default collapsed width */
  overflow: hidden;
  transition: width 0.3s ease-in-out;
}
.menu-section:hover .main-menu {
  width: 220px ;
}
.main-menu:hover {
  width: 220px; /* Expanded width */
} 

.main-menu>ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 250px;
}

.main-menu li>a {
  display: flex;
  align-items: center;
  padding: 12px;
  color: #999;
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  transition: background 0.3s;
}

.main-menu .nav-icon {
  width: 60px;
  text-align: center;
  font-size: 18px;
}

.main-menu .nav-text {
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
  overflow: hidden;
}

.main-menu:hover .nav-text {
  opacity: 1;
}

.main-menu li:hover>a {
  color: #fff;
  background-color: #000000;
}
.vertical-timeline-element-content
{
  box-shadow: none !important;
}
.vertical-timeline-element-box {
  transition: all 0.6s ease-in-out;
  position: relative;
}

.vertical-timeline-element-box .top-date {
  max-height: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transform: translateY(-5px);
  transition: all 0.6s ease-in-out;
}

.vertical-timeline-element-box .vertical-side-box {
  opacity: 1;
  min-width: fit-content;
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.vertical-timeline-element-box .vertical-timeline-element-info {
  max-height: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transform: translateY(-5px);
  transition: all 0.6s ease-in-out;
}

.vertical-timeline-element-description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px !important;
}

/* Hover Effects */
.vertical-timeline-element-box:hover .top-date,
.vertical-timeline-element-box:hover .vertical-timeline-element-info {
  opacity: 1;
  max-height: 500px; /* Adjust based on content */
  height: auto;
  transform: translateY(0);
}

.vertical-timeline-element-box:hover .vertical-side-box {
  opacity: 0;
  transform: scaleX(0);
  transition: transform 0.6s ease-in-out, opacity 0.1s ease-in-out;
}
